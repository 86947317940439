<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Ve"
            data-toggle="tab"
            href="#Internos"
            v-show="Object.keys($parent.vehiculosIn).length > 0"
            ><b>Internos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Nacionales"
            data-toggle="tab"
            href="#Nacionales"
            v-show="Object.keys($parent.vehiculosNa).length > 0"
            ><b>Nacionales</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Inactivos"
            data-toggle="tab"
            href="#Inactivos"
            v-show="Object.keys($parent.vehiculosInact).length > 0"
            ><b>Inactivos</b></a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <!---- Vehículo Internos ----->
        <div
          class="tab-pane fade"
          id="Internos"
          v-show="Object.keys($parent.vehiculosIn).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                                                                      table table-bordered table-striped table-hover table-sm
                                                                    "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculoIn"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresaIn"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Conductor
                      </th>
                      <th>
                        Sitio
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitioIn"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha ultima posicion</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in $parent.vehiculosIn.data" :key="item.id">
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td>
                        {{ item.cs_servicio_interno.persona.apellidos }}
                        {{ item.cs_servicio_interno.persona.nombres }}
                      </td>
                      <td>
                        {{ item.cs_servicio_interno.sitio.nombre }}
                      </td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.fecha_hora_ultimo_reporte }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            item.cs_servicio_interno.estado == 1
                              ? 'bg-navy'
                              : item.cs_servicio_interno.estado == 2
                              ? 'badge-info'
                              : item.cs_servicio_interno.estado == 3
                              ? 'badge-danger'
                              : item.cs_servicio_interno.estado == 4
                              ? 'badge-warning'
                              : item.cs_servicio_interno.estado == 5
                              ? 'bg-green'
                              : item.cs_servicio_interno.estado == 6
                              ? 'bg-purple'
                              : ''
                          "
                        >
                          {{ item.cs_servicio_interno.nEstadoIn }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosIn.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculosIn.from }}</b> al
                <b>{{ $parent.vehiculosIn.to }}</b> de un total:
                <b>{{ $parent.vehiculosIn.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosIn"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            >
            </pagination>
          </div>
        </div>

        <!--- Vehículo Nacionales ---->
        <div
          class="tab-pane fade"
          id="Nacionales"
          v-show="Object.keys($parent.vehiculosNa).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                                                                      table table-bordered table-striped table-hover table-sm
                                                                    "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculoNa"
                          @keyup.enter="$parent.showCarros()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresaNa"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                      </th>
                      <th>Fecha de Solicitud</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in $parent.vehiculosNa.data" :key="item.id">
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td v-if="item.cs_servicio_nacional.persona">
                        {{ item.cs_servicio_nacional.persona.apellidos }}
                        {{ item.cs_servicio_nacional.persona.nombres }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.cs_servicio_nacional">
                        {{ item.cs_servicio_nacional.fecha_fin }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            item.cs_servicio_nacional.estado == 1
                              ? 'bg-navy'
                              : item.cs_servicio_nacional.estado == 2
                              ? 'badge-info'
                              : item.cs_servicio_nacional.estado == 3
                              ? 'badge-danger'
                              : item.cs_servicio_nacional.estado == 4
                              ? 'badge-warning'
                              : item.cs_servicio_nacional.estado == 5
                              ? 'bg-green'
                              : item.cs_servicio_nacional.estado == 6
                              ? 'bg-purple'
                              : ''
                          "
                        >
                          {{ item.cs_servicio_nacional.nEstadoNa }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosNa.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculosNa.from }}</b> al
                <b>{{ $parent.vehiculosNa.to }}</b> de un total:
                <b>{{ $parent.vehiculosNa.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosNa"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            >
            </pagination>
          </div>
        </div>

        <!--- Vehículo Inactivos ---->
        <div
          class="tab-pane fade"
          id="Inactivos"
          v-show="Object.keys($parent.vehiculosInact).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                                                                      table table-bordered table-striped table-hover table-sm
                                                                    "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculosInact"
                          @keyup.enter="$parent.showCarros()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresaInact"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                      </th>
                      <th>Fecha de Solicitud</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosInact.data"
                      :key="item.id"
                    >
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td v-if="item.cs_servicio_nacional">
                        {{ item.cs_servicio_nacional.persona.apellidos }}
                        {{ item.cs_servicio_nacional.persona.nombres }}
                      </td>
                      <td v-else-if="item.cs_servicio_interno">
                        {{ item.cs_servicio_interno.persona.apellidos }}
                        {{ item.cs_servicio_interno.persona.nombres }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.cs_servicio_nacional">
                        {{ item.cs_servicio_nacional.fecha_fin }}
                      </td>
                      <td v-else-if="item.cs_servicio_interno">
                        {{
                          item.cs_servicio_interno.postulacion
                            .det_solicitud_interna.solicitud_interna.fecha
                        }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.cs_servicio_nacional">
                        <span
                          class="badge"
                          :class="
                            item.cs_servicio_nacional.estado == 1
                              ? 'bg-navy'
                              : item.cs_servicio_nacional.estado == 2
                              ? 'badge-info'
                              : item.cs_servicio_nacional.estado == 3
                              ? 'badge-danger'
                              : item.cs_servicio_nacional.estado == 4
                              ? 'badge-warning'
                              : item.cs_servicio_nacional.estado == 5
                              ? 'bg-green'
                              : item.cs_servicio_nacional.estado == 6
                              ? 'bg-purple'
                              : ''
                          "
                        >
                          {{ item.cs_servicio_nacional.nEstadoNa }}
                        </span>
                      </td>
                      <td v-else-if="item.cs_servicio_interno">
                        <span
                          class="badge"
                          :class="
                            item.cs_servicio_interno.estado == 1
                              ? 'bg-navy'
                              : item.cs_servicio_interno.estado == 2
                              ? 'badge-info'
                              : item.cs_servicio_interno.estado == 3
                              ? 'badge-danger'
                              : item.cs_servicio_interno.estado == 4
                              ? 'badge-warning'
                              : item.cs_servicio_interno.estado == 5
                              ? 'bg-green'
                              : item.cs_servicio_interno.estado == 6
                              ? 'bg-purple'
                              : ''
                          "
                        >
                          {{ item.cs_servicio_interno.nEstadoIn }}
                        </span>
                      </td>
                      <td v-else>
                        <span class="badge bg-warning">Sin Servicio</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosInact.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculosInact.from }}</b> al
                <b>{{ $parent.vehiculosInact.to }}</b> de un total:
                <b>{{ $parent.vehiculosInact.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosInact"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";

export default {
  name: "CsMapasListVehiculos",
  components: {
    pagination,
  },
  data() {
    return {
      filtros: {},
    };
  },

  methods: {},
};
</script>
